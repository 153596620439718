.login-button {
  margin-right: 15px;
}

.link-forgot-password {
  float: right;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-form-login {
    margin-top: 50px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}
